import Vue from "vue";
import { ValidationObserver, ValidationProvider, extend } from "vee-validate";

import {
  required,
  required_if,
  confirmed,
  min_value,
  max_value,
  regex,
  numeric,
  min,
  max,
  between,
} from "vee-validate/dist/rules";

import i18n from "./i18n";

export default {
  setupLocale() {
    extend("min", {
      ...min,
      message: i18n.t("vee-min"),
    });

    extend("max", {
      ...max,
      message: i18n.t("vee-max"),
    });

    extend("between", {
      ...between,
      message: i18n.t("vee-between"),
    });

    extend("required_vat", (value) => {
      if (value.length == 11) {
        return true;
      }

      return i18n.t("vee-required-vat");
    });

    extend("required_fc", (value) => {
      if (value.length == 16) {
        return true;
      }

      return i18n.t("vee-required-fc");
    });

    extend("required_fc_business", (value) => {
      if (value.length == 11 || value.length == 16) {
        return true;
      }

      return i18n.t("vee-required-fc");
    });

    extend("required_name_or_business", {
      ...required,
      message: i18n.t("vee-required-name-or-business"),
    });

    extend("required_if", {
      ...required_if,
      message: i18n.t("vee-required"),
    });

    extend("required", {
      ...required,
      message: i18n.t("vee-required"),
    });

    extend("email", (value) => {
      let regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      let res = regex.test(value);
      if (!res) return i18n.t("vee-email");
      return true;
    });

    extend("emails", (value) => {
      let values = value.split(";");
      for (let i = 0; i < values.length; i++) {
        if (values[i].length == 0) continue;
        let regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        let res = regex.test(values[i]);
        if (!res) return i18n.t("vee-email");
      }
      return true;
    });

    extend("hhmm", {
      validate: (value) => {
        return /^([01]\d|2[0-3]):([0-5]\d)$/.test(value) || i18n.t("vee-hhmm");
      },
    });

    extend("ip", {
      validate: (value) => {
        return /^([0-9]{1,3}\.){3}[0-9]{1,3}$/.test(value) || i18n.t("vee-ip");
      },
    });


    extend("confirmed", {
      ...confirmed,
      message: i18n.t("vee-confirmed"),
    });

    extend("min_value", {
      ...min_value,
      message: i18n.t("vee-min_value"),
    });

    extend("max_value", {
      ...max_value,
      message: i18n.t("vee-max_value"),
    });

    extend("regex", {
      ...regex,
      message: i18n.t("vee-regex"),
    });

    extend("numeric", {
      ...numeric,
      message: i18n.t("vee-numeric"),
    });

    extend("required_vat", (value) => {
      if (value.length == 11) {
        return true;
      }

      return i18n.t("vee-required-vat");
    });

    extend("optional_phone", (value) => {
      if (value.length == 0) {
        return true;
      }
      let r = /^\+[\d ]+$/;
      let res = value.match(r);

      if (res && res[0] == value) return true;
      else return i18n.t("vee-invalid-int-phone");
    });

    extend("phone", (value) => {
      let r = /^\+[\d ]+$/;
      let res = value.match(r);

      if (res && res[0] == value) return true;
      else return i18n.t("vee-invalid-int-phone");
    });

    extend("decimal", {
      validate: (value, { decimals = "*", separator = "." } = {}) => {
        if (i18n.locale == "it") separator = ",";
        if (value === null || value === undefined || value === "") {
          return {
            valid: false,
          };
        }
        if (Number(decimals) === 0) {
          return {
            valid: /^-?\d*$/.test(value),
          };
        }
        const regexPart = decimals === "*" ? "+" : `{1,${decimals}}`;
        const regex = new RegExp(
          `^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`
        );
        return {
          valid: regex.test(value),
          data: {
            serverMessage: "Only decimal values are available",
          },
        };
      },
      message: i18n.t("vee-decimal"),
    });

    extend("strong_password", (value) => {
      let r1 = /(?=.{8,})/; // The password is at least 8 characters long ().
      let r2 = /(?=.*[A-Z])/; // The password has at least one uppercase letter ().
      let r3 = /(?=.*[a-z])/; // The password has at least one lowercase letter ().
      let r4 = /(?=.*[0-9])/; // The password has at least one digit ().
      let r5 = /[^A-Za-z0-9]/; // The password has at least one special character ().

      let res = value.match(r1);
      if (!res || (res && !res[0] == value)) return i18n.t("strong_password_1");
      res = value.match(r2);
      if (!res || (res && !res[0] == value)) return i18n.t("strong_password_2");
      res = value.match(r3);
      if (!res || (res && !res[0] == value)) return i18n.t("strong_password_3");
      res = value.match(r4);
      if (!res || (res && !res[0] == value)) return i18n.t("strong_password_4");
      res = value.match(r5);
      if (!res || (res && !res[0] == value)) return i18n.t("strong_password_5");

      return true;
    });
  },
};

Vue.component("ValidationObserver", ValidationObserver);
Vue.component("ValidationProvider", ValidationProvider);
